@font-face {
    font-family: 'Clearface';
    src: url('ClearfaceBold.woff2') format('woff2'),
        url('ClearfaceBold.woff') format('woff'),
        url('ClearfaceBold.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Clearface';
    src: url('Clearface-Regular.woff2') format('woff2'),
        url('Clearface-Regular.woff') format('woff'),
        url('Clearface-Regular.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

