/* RESETS */

:root {
	--background-color: #ffffff;
	--text-color: #000000;
	--primary-manual-text-color: #222222;
	--manual-heading-details-color: #111111;
	--primary-color: #893125;
	--secondary-color: #ba5951;
	--bible-verse-tag-color: #0c467b;

	/* SVGs */
	--svg-stroke-color: #111111;
	--svg-fill-color: none;

	/* CARDs */
	--manual-card-bg-color: #f7fafc;

	/* Skeleton */
	--skeleton-base-color: #e0e0e0;
	--skeleton-highlight-color: #f5f5f5;
}

[data-theme='dark'] {
	--background-color: #121212;
	--text-color: #ffffff;
	--primary-manual-text-color: #f2f2f2;
	--manual-heading-details-color: #f1f1f1;
	--primary-color: #bb86fc;
	--secondary-color: #03dac6;
	--bible-verse-tag-color: #105ea7;

	/* SVGs */
	--svg-stroke-color: #ffffff;
	--svg-fill-color: none;

	/* CARDs */
	--manual-card-bg-color: #91625e;

	/* Skeleton */
	--skeleton-base-color: #333333;
	--skeleton-highlight-color: #4a4a4a;
}

*,
*::before,
*::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

*:focus {
	outline: none;
}

html {
	font: 62.5% / 1.15 sans-serif; /* 1rem */
	max-width: 100%;
	overflow-x: hidden;
}

h1,
h2 {
	margin-bottom: 0;
}

ul {
	list-style: none;
	padding: 0;
}

button {
	border: none;
	margin: 0;
	padding: 0;
	width: auto;
	overflow: visible;
	background: transparent;
	color: inherit;
	font: inherit;
	cursor: pointer;
	line-height: normal;
	-webkit-font-smoothing: inherit;
	-moz-osx-font-smoothing: inherit;
	-webkit-appearance: none;
}

button::-moz-focus-inner {
	border: 0;
}

button,
input,
optgroup,
select,
textarea {
	font-family: inherit;
	font-size: 100%;
	line-height: 1.15;
	margin: 0;
	outline: none;
}

button,
input {
	/* 1 */
	overflow: visible;
}

input[type='text'] {
	border-radius: 0;
}

body {
	width: 100%;
	margin: 0 auto;
	font-size: 1.3rem/1.25;
	font-family: 'Apercu';
	/* color: #000000; */
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;

	background-color: var(--background-color);
	color: var(--text-color);
	transition: all 0.4s ease-in-out;
}

@media screen and (min-width: 620px) {
	body {
		font-size: 1.6rem;
		line-height: 1.31579;
	}
}

a,
a:visited {
	padding: 0;
	margin: 0;
	text-decoration: none;
	color: inherit;
}

img {
	width: 100%;
}

/* END RESETS */
